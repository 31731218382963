import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://15438d63f56c44933d974d27574d2880@o4507351643389952.ingest.us.sentry.io/4507354965540864",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});